import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import ErrorBoundary from './components/ErrorBoundary';

const Header = lazy(() => import('./components/Header'));
const SearchBar = lazy(() => import('./components/SearchBar'));
const Posters = lazy(() => import('./components/Posters'));
const Poster = lazy(() => import('./components/Poster'));
const Combined = lazy(() => import('./components/Combined'));
const Footer = lazy(() => import('./components/Footer'));

const Layout = ({ children }) => (
  <>
    <Header />
    {children}
    <Footer />
  </>
);

const LoadingFallback = () => (
  <div className="loading-container">
    <div className="spinner-border text-dark" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>
);

function App() {
  return (
    <ErrorBoundary>
      <Router>
        <Suspense fallback={<LoadingFallback />}>
          <div className="app">
            <Routes>
              <Route path="/" element={<Layout />} />

              <Route
                path="/:type/:media"
                element={
                  <Layout>
                    <SearchBar />
                  </Layout>
                }
              />

              <Route
                path="/posters/:type"
                element={
                  <Layout>
                    <Posters />
                  </Layout>
                }
              />

              <Route
                path="/poster/:poster_path"
                element={
                  <Layout>
                    <Poster />
                  </Layout>
                }
              />

              <Route
                path="/combine/:poster_path/:banner/:format"
                element={
                  <Layout>
                    <Combined />
                  </Layout>
                }
              />
            </Routes>
          </div>
        </Suspense>
      </Router>
    </ErrorBoundary>
  );
}

export default App;
